import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants';
import { TabState } from '../../constants/settings-events';
import { Analytics } from '../../services/analytics';
import { encodeBase64Url } from '../../services/encode-base64';
import { PlansApi } from '../../services/plans';
import { CheckoutData, PopupEnum } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { WIDGET_TYPE } from '../../utils/bi';
import { toError } from '../../utils/errors';
import { getOrderedVisiblePlans } from '../../utils/plan-visibility';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { ListProps } from '../PackagePicker/Widget/List';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appParams: ControllerParams['controllerConfig']['appParams'],
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
  ) {}

  public async initialize() {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.WidgetLoaded);

    const { plans } = await this.fetchAndOrderPlans();
    this.setProps({
      plans,
      tabState: TabState.REGULAR,
      selectPlan: this.navigateToCheckout,
      hidePopup: this.hidePopup,
      popup: null,
    });

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          widgetType: WIDGET_TYPE.list,
        }),
      );
    }
    this.analytics.addProductImpression(plans);
  }

  fetchAndOrderPlans = async () => {
    let plans: PublicPlan[] = [];
    try {
      plans = await this.plansApi.loadPaidPlans();
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';
    const useNewLimit = this.flowAPI.experiments.enabled(EXPERIMENTS.PLAN_LIMIT);
    return { plans: getOrderedVisiblePlans(useFixture ? this.demoPlans : plans, this.settings, useNewLimit) };
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.NavigateToList);

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({ popup: PopupEnum.checkoutPreview });
      this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
      return;
    }

    this.flowAPI.bi?.report(planPurchaseClick({ planGuid: plan.id ?? '', widgetType: 'list' }));
    this.analytics.clickProduct(plan);

    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };

    // #membershipPlanPickerTpa1 is default Package Picker velo element id
    // If the user will change it the scroll will stop working and the navigation will be "jumpy"
    this.setCurrentPath('/payment/' + encodeBase64Url(checkoutData) + '#membershipPlanPickerTpa1');
  };

  private hidePopup = () => this.setProps({ popup: null });

  private setCurrentPath = async (path: string) => {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId: 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path);
    this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
  };
}
